import * as PopoverPrimitive from "@radix-ui/react-popover";
import { forwardRef, useState } from "react";
import { cn } from "~/utils/cn";
import { SelectProps, SelectVariant } from "../../select.types";
import { getSelected } from "./drop-list.utils";
import { PopoverContent } from "./components";
import { Tag } from "~/scalis-components/core/tag";
import { Popover, PopoverTrigger } from "~/scalis-components/core/popover";
import { SelectedContent } from "./components/selected-content/selected-content";
import {
  buttonClassName,
  selectedContentContainerClassName,
} from "./drop-list.styles";

export const DropList = forwardRef<HTMLButtonElement, SelectProps>(
  (
    {
      emptyTerm,
      multi,
      collisionPadding,
      name = "",
      options,
      placeholder = null,
      searchPlaceholder,
      setValue,
      value,
      searchByLabel = true,
      disabled,
      className,
      icon,
      onBlur,
      onChange,
      onInputChange,
      variant = SelectVariant.default,
      dataType,
      startAdornment,
      scrollHeight,
      emptyIcon,
      setIsDropListOpen,
      hideSelectedValue,
      popover,
      optionClassName,
      ...props
    },
    ref,
  ) => {
    const [open, setOpen] = useState<boolean>(false);

    let selectedValue;
    let hasSelectedValue;
    if (props.selectedValue) {
      selectedValue = props.selectedValue;
      hasSelectedValue = !!props.selectedValue;
    } else {
      const selectedOptions = getSelected(value, options);
      selectedValue = multi
        ? selectedOptions?.map(option => {
            return (
              <Tag
                small
                key={option?.value}
                text={option?.label}
                closeButton={{
                  hasCloseButton: true,
                  onClose: () => {
                    if (Array.isArray(value)) {
                      const newValue = value?.filter?.(
                        (v: any) => v !== option?.value,
                      );
                      if (setValue) setValue(name, newValue);
                    }
                  },
                }}
              />
            );
          })
        : selectedOptions?.[0]?.label;

      hasSelectedValue = !hideSelectedValue
        ? selectedOptions.length > 0
        : false;
    }

    const handleSelect = (newValue: string) => {
      if (multi) {
        const values = value;
        if (Array.isArray(values) && values?.includes(newValue)) {
          setValue?.(
            name,
            values?.filter?.((v: any) => v !== newValue),
            { shouldValidate: true },
          );
        }
        return setValue?.(name, [...(values || []), newValue], {
          shouldValidate: true,
        });
      }
      props.onSelect?.(newValue);
      setValue?.(name, newValue, { shouldValidate: true });
      return handleOpenChange(false);
    };

    const handleOpenChange = (state: boolean) => {
      if (!state && onBlur) {
        onBlur();
      }
      setOpen(state);
      setIsDropListOpen?.(state);
    };

    const closePopover = () => handleOpenChange(false);

    return (
      <Popover open={open} onOpenChange={handleOpenChange}>
        <PopoverTrigger asChild>
          <button
            ref={ref}
            role="combobox"
            aria-label={props["aria-label"]}
            disabled={disabled}
            data-is-open={open}
            className={cn(buttonClassName({ variant }), className)}
          >
            {startAdornment}
            <div
              className={selectedContentContainerClassName({ variant })}
              aria-label="Selected value"
            >
              <SelectedContent
                variant={variant}
                placeholder={placeholder}
                icon={icon}
                selectedValue={selectedValue}
                emptyTerm={emptyTerm}
                hasSelectedValue={hasSelectedValue}
              />
            </div>
            {variant !== SelectVariant.inline && icon}
          </button>
        </PopoverTrigger>
        <PopoverPrimitive.Content
          className={cn(
            "data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0",
            "data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95",
            "data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2",
            "data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 z-50",
            "text-popover-foreground outline-none",
            "text-sm font-normal leading-6 text-neutral-primary",
            "border border-neutral-00 shadow-drop-list",
            "rounded-xl bg-neutral-00",
            {
              "w-popover-trigger": variant !== SelectVariant.filter,
            },
          )}
          sideOffset={0}
          collisionPadding={collisionPadding}
          align={variant === SelectVariant.filter ? "start" : undefined}
        >
          {popover?.({ closePopover }) ?? (
            <PopoverContent
              searchPlaceholder={searchPlaceholder}
              emptyTerm={emptyTerm}
              scrollHeight={scrollHeight}
              options={options}
              multi={multi}
              value={value}
              searchByLabel={searchByLabel}
              onChange={onChange}
              onInputChange={onInputChange}
              handleSelect={handleSelect}
              optionClassName={optionClassName}
            />
          )}
        </PopoverPrimitive.Content>
      </Popover>
    );
  },
);
