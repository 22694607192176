"use client";

import { useState } from "react";
import { cn } from "~/utils/cn";
import { DropList } from "./components";
import {
  containerBaseClassName,
  dropListButtonClassName,
  dropListContainerClassName,
  innerContainerBaseClassName,
  labelClassName,
  selectClassName,
} from "./select.styles";
import {
  SelectKind,
  SelectProps,
  SelectSize,
  SelectVariant,
} from "./select.types";
import { DefaultInfoIcon, Tooltip, TooltipProvider } from "../tooltip";
import { CurrentIcon } from "./components/drop-list/components/current-icon/current-icon";

export const Select = ({
  label,
  error,
  disabled,
  required,
  containerClassName,
  innerContainerClassName,
  disabledLabel,
  collisionPadding,
  onBlur,
  searchPlaceholder,
  size = SelectSize.large,
  optional,
  tooltip,
  variant = SelectVariant.default,
  ...props
}: SelectProps) => {
  const [isDropListOpen, setIsDropListOpen] = useState(false);

  const getSelectKind = (): SelectKind => {
    if (error) {
      return SelectKind.error;
    }
    if (props.value) {
      return SelectKind.filled;
    }
    if (disabled) {
      return SelectKind.disabled;
    }
    return SelectKind.default;
  };

  const className = selectClassName({
    size: props.multi ? SelectSize.variable : size,
    kind: getSelectKind(),
    variant,
  });

  return (
    <TooltipProvider>
      <div
        className={cn(containerBaseClassName({ variant }), containerClassName)}
      >
        <div
          className={cn(
            innerContainerBaseClassName({ variant }),
            innerContainerClassName,
          )}
        >
          {label && (
            <span className={labelClassName({ variant })}>
              {label}

              {required && "*"}

              {optional && " (Optional)"}

              {tooltip && (
                <Tooltip
                  content={tooltip}
                  tooltipTrigger={
                    <DefaultInfoIcon className="text-neutral-20 ml-1" />
                  }
                />
              )}
            </span>
          )}

          <div className={dropListContainerClassName({ variant })}>
            <DropList
              {...props}
              aria-label={label}
              searchPlaceholder={searchPlaceholder}
              className={cn(dropListButtonClassName({ variant }), className)}
              icon={
                <CurrentIcon
                  name={props.name!}
                  setValue={props.setValue}
                  value={props.value}
                  variant={variant}
                  isDropListOpen={isDropListOpen}
                />
              }
              disabled={disabled}
              disabledLabel={disabledLabel}
              collisionPadding={collisionPadding}
              variant={variant}
              setIsDropListOpen={setIsDropListOpen}
            />
          </div>

          {error && (
            <span className="mt-1 flex items-center text-xs/6 text-status-critical">
              {error}
            </span>
          )}
        </div>
      </div>
    </TooltipProvider>
  );
};
